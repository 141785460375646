'use client'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { usePathname } from 'next/navigation'
import { atom, useAtom, useAtomValue } from 'jotai'
import { getLocalStorage, setLocalStorage, utcDate, whisper } from '@/utils'
import { CACHE_KEYS } from '@/constants'
import { auth0SignInAtom, checkInStatusAtom } from '@/atoms'
import useSurvey from './useSurvery'
import useTemplateOfTheDay from './useTemplateOfTheDay'
import { uniq } from 'lodash-es'
import { useCachedNewFeatureAnnouncement } from '@/hooks/useNewFeatureAnnouncement'
import dayjs from 'dayjs'

export interface UsePopupsResult {
  showTemplateOfTheDay: boolean
  showNewFeatureDialog: boolean
  showFollowUsDialog: boolean
}

type PopupId = 'templateOfTheDay' | 'newFeature' | 'followUs' | 'checkin'

export const followUsDialogOpenAtom = atom<boolean>(getLocalStorage<boolean>(CACHE_KEYS.FOLLOW_US_DIALOG) ?? true)
export const readedNewFeatureKeysAtom = atom<string[]>(getLocalStorage<string[]>(CACHE_KEYS.NEW_FEATURE_DIALOG) ?? [])
export const activePopupIdAtom = atom<PopupId | null>(null)

export const checkInDialogOpenAtom = atom<boolean>(
  getLocalStorage<string>(CACHE_KEYS.CHECKIN_DIALOG_LAST_DATE) !== utcDate(),
)

export default function usePopups() {
  const [followUsDialogOpen, setFollowUsDialogOpen] = useAtom(followUsDialogOpenAtom)
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const [checkInDialogOpen, setCheckInDialogOpen] = useAtom(checkInDialogOpenAtom)
  const { data: newFeatureAnnouncement, isValidating: newFeatureAnnouncementLoading } =
    useCachedNewFeatureAnnouncement()

  const lastPopupId = useRef<PopupId | null>(null)

  const { data: templateOfTheDay } = useTemplateOfTheDay()

  const isLogin = useAtomValue(auth0SignInAtom)
  const { showSurvey } = useSurvey()

  const [activePopupId, setActivePopupId] = useAtom(activePopupIdAtom)

  const pathname = usePathname()

  useEffect(() => {
    lastPopupId.current = activePopupId
  }, [activePopupId])

  const shouldNotPopup = useMemo(() => {
    return pathname.startsWith('/auth') || pathname.startsWith('/onboarding') || pathname === '/event'
  }, [pathname])

  const loading = newFeatureAnnouncementLoading
  const checkInStatus = useAtomValue(checkInStatusAtom)

  useEffect(() => {
    if (loading || lastPopupId.current) {
      return
    }

    if (followUsDialogOpen) {
      setActivePopupId('followUs')
    } else if (newFeatureAnnouncement && !readedNewFeatureKeys.includes(newFeatureAnnouncement.key)) {
      setActivePopupId('newFeature')
    } else if (
      (!checkInStatus?.last_checkin_time || dayjs(checkInStatus?.last_checkin_time).isBefore(dayjs().startOf('day'))) &&
      checkInDialogOpen
    ) {
      setActivePopupId('checkin')
    } else {
      setActivePopupId(null)
    }
  }, [
    loading,
    followUsDialogOpen,
    templateOfTheDay,
    checkInDialogOpen,
    newFeatureAnnouncement,
    readedNewFeatureKeys,
    checkInStatus,
    setActivePopupId,
  ])

  const disableAllPopups = loading || shouldNotPopup || !isLogin || showSurvey

  const showTemplateOfTheDay = useMemo(() => {
    if (disableAllPopups) {
      return false
    }

    return activePopupId === 'templateOfTheDay'
  }, [disableAllPopups, activePopupId])

  const showNewFeatureDialog = useMemo(() => {
    if (disableAllPopups) {
      return false
    }
    return !!(
      activePopupId === 'newFeature' &&
      newFeatureAnnouncement?.key &&
      !readedNewFeatureKeys.includes(newFeatureAnnouncement?.key)
    )
  }, [activePopupId, disableAllPopups, readedNewFeatureKeys, newFeatureAnnouncement])

  const showFollowUsDialog = useMemo(() => {
    if (disableAllPopups) {
      return false
    }
    return activePopupId === 'followUs' && followUsDialogOpen
  }, [activePopupId, disableAllPopups, followUsDialogOpen])

  const showCheckinDialog = useMemo(() => {
    if (disableAllPopups) {
      return false
    }
    return activePopupId === 'checkin'
  }, [activePopupId, disableAllPopups])

  const closeDialogById = useCallback(
    (id: string) => {
      const newKeys = uniq([...readedNewFeatureKeys, id])
      setReadedNewFeatureKeys(newKeys)
      setLocalStorage(CACHE_KEYS.NEW_FEATURE_DIALOG, newKeys)
      setActivePopupId(null)
    },
    [readedNewFeatureKeys, setReadedNewFeatureKeys, setActivePopupId],
  )

  const closeNewFeatureDialog = useCallback(() => {
    const id = newFeatureAnnouncement?.key ?? ''
    closeDialogById(id)
  }, [closeDialogById, newFeatureAnnouncement])

  const closeTemplateOfTheDayDialog = useCallback(() => {
    closeDialogById(templateOfTheDay?.popup_id ?? '')
  }, [closeDialogById, templateOfTheDay])

  const closeFollowUsDialog = useCallback(() => {
    setFollowUsDialogOpen(false)
    setActivePopupId(null)
  }, [setFollowUsDialogOpen, setActivePopupId])

  const closeCheckinDialog = useCallback(() => {
    setActivePopupId(null)
    setCheckInDialogOpen(false)
    setLocalStorage(CACHE_KEYS.CHECKIN_DIALOG_LAST_DATE, utcDate())
  }, [setActivePopupId, setCheckInDialogOpen])

  const openTemplateOfTheDay = useCallback(() => {
    setActivePopupId('templateOfTheDay')
    setReadedNewFeatureKeys((keys) => keys.filter((key) => key !== templateOfTheDay?.popup_id))
  }, [setReadedNewFeatureKeys, templateOfTheDay, setActivePopupId])

  const openNewFeatureDialog = useCallback(() => {}, [])
  const openFollowUsDialog = useCallback(() => {
    setActivePopupId('followUs')
  }, [setActivePopupId])

  const openCheckinDialog = useCallback(() => {
    setActivePopupId('checkin')
  }, [setActivePopupId])

  return {
    showTemplateOfTheDay,
    showNewFeatureDialog,
    showFollowUsDialog,
    showCheckinDialog,
    closeTemplateOfTheDayDialog,
    closeNewFeatureDialog,
    closeFollowUsDialog,
    closeCheckinDialog,
    openTemplateOfTheDay,
    openNewFeatureDialog,
    openFollowUsDialog,
    openCheckinDialog,
  }
}
